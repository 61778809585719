const actions = {
    SET_STATE: "authorize.net/SET_STATE",
    CREATE_CUSTOMER_PROFILE: "authorize.net/CREATE_CUSTOMER_PROFILE",
    UPDATE_PAYMENT_PROFILE: "authorize.net/UPDATE_PAYMENT_PROFILE",
    CREATE_SUBSCRIPTION: "authorize.net/CREATE_SUBSCRIPTION",
    UPDATE_SUBSCRIPTION: "authorize.net/UPDATE_SUBSCRIPTION",
    CANCEL_SUBSCRIPTION: "authorize.net/CANCEL_SUBSCRIPTION"
};

export default actions;
