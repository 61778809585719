const actions = {
    SET_STATE: "search/SET_STATE",
    SINGLE_SEARCH: "search/SINGLE_SEARCH",
    ADD_CREDIT: "search/ADD_CREDIT",
    UPLOAD_LIST: "search/UPLOAD_LIST",
    BATCH_SEARCH: "search/BATCH_SEARCH",
    SUCCESS_UPLOAD: "search/SUCESS_UPLOAD"
};

export default actions;
